<template>
  <div>
    <b-row class="mt-5">
      <b-col>
        <b-card title="Perfiles">
          <button
            class="btn btn-sm btn-circle btn-outline-primary mb-2 mr-3"
            @click="loadRoles()"
            v-if="can('/roles')"
          >
            <i class="flaticon-refresh"></i>
          </button>
          <button
            class="btn btn-sm mb-2 btn-circle btn-primary"
            @click="addRole()"
            v-if="can('create-role')"
          >
            <i class="flaticon-users-1"></i> Agregar
          </button>
          <transition name="fade">
            <b-row v-if="items.length">
              <b-col class="dx-viewport">
                <DxDataGrid
                  :data-source="items"
                  :show-borders="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="true"
                  :columnAutoWidth="true"
                  :allow-column-resizing="true"
                >
                  <DxSearchPanel
                    :visible="true"
                    :width="240"
                    placeholder="Buscar..."
                  />
                  <DxPaging :page-size="15" />
                  <DxPager
                    :visible="true"
                    infoText="Página {0} de {1} ({2} registros)"
                    :allowed-page-sizes="pageSizes"
                    display-mode="full"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :show-navigation-buttons="true"
                  />
                  <DxHeaderFilter :visible="true" />
                  <DxFilterRow :visible="true">
                    <DxOperationDescriptions
                      equal="Igual a"
                      notEqual="Diferente de"
                      startsWith="Empieza con"
                      endsWith="Termina con"
                      contains="Contiene"
                      between="Entre"
                      notContains="No contiene"
                      lessThanOrEqual="Menor o igual"
                      greaterThanOrEqual="Mayor o igual"
                      lessThan="Menor que"
                      greaterThan="Mayor que"
                    />
                  </DxFilterRow>
                  <DxColumn
                    data-field="name"
                    caption="Perfil"
                    alignment="center"
                  />
                  <DxColumn
                    caption=""
                    width="150"
                    cell-template="cellTemplate"
                  />
                  <template #cellTemplate="{ data: cellData }">
                    <div class="text-center">
                      <router-link
                        :to="{
                          name: 'rolesUpdate',
                          params: { id: cellData.data.id },
                        }"
                        v-if="can('update-role')"
                        class="btn btn-sm btn-circle action"
                      >
                        <i class="flaticon2-pen text-success"></i>
                      </router-link>
                      <button
                        class="btn btn-sm btn-circle action"
                        v-if="can('delete-role')"
                        @click="deleteRole(cellData.data.id)"
                      >
                        <i class="flaticon2-rubbish-bin text-danger"></i>
                      </button>
                    </div>
                  </template>
                </DxDataGrid>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col>
                <div v-if="!loading">
                  <div class="text-center">
                    <img src="assets/img/empty.png" alt="Empty" />
                    <p class="text-muted">No hay información para mostrar</p>
                  </div>
                </div>
                <div class="text-center" v-else>
                  <img src="assets/img/loader.gif" alt="Loading..." />
                </div>
              </b-col>
            </b-row>
          </transition>
        </b-card>
      </b-col>
    </b-row>
    <CreateRole ref="CROLE" @loadRoles="loadRoles" v-if="can('create-role')" />
  </div>
</template>
<style>
.action:hover i {
  opacity: 0.7 !important;
}
</style>
<script>
import axios from "axios";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
} from "devextreme-vue/data-grid";
import CreateRole from "./CreateRole.vue";
import can from "@/permission";
export default {
  name: "role-component",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    CreateRole,
  },
  data() {
    return {
      items: [],
      permission: [],
      loading: false,
      pageSizes: [5, 10, 15, 30, 50],
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
    };
  },
  mounted() {
    this.loadRoles();
  },
  methods: {
    can: can,
    addRole() {
      // code
      const CROLE = this.$refs.CROLE;
      CROLE.show = true;
      CROLE.loadPermission();
    },
    loadRoles() {
      this.loading = true;
      this.items = [];
      axios
        .get("roles")
        .then((res) => {
          this.items = res.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    deleteRole(id) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar el permiso?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/roles/${id}`)
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "success"
                  );
                  this.loadRoles();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
  },
};
</script>
