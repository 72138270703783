<template>
  <b-modal
    title="Crear perfil"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col md="6">
        <div class="form-group">
          <label>Perfil</label>
          <input
            v-model="form.name"
            type="text"
            class="form-control form-control-sm"
            placeholder="Component"
          />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <h5>Listado de permisos</h5>
        <hr />
      </b-col>
      <b-col md="3" v-for="(item, idx) in permission" :key="idx">
        <div class="checkbox-inline">
          <label class="checkbox checkbox-outline checkbox-primary">
            <input
              type="checkbox"
              name="Checkboxes15"
              v-model="form.permissions"
              :value="item.id"
            />
            <span></span>{{ item.guard_name }}</label
          >
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-circle" @click="() => (show = false)">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button class="btn btn-sm btn-success" @click="create()">
          <i class="la la-save"></i> {{ loading ? "Cargando..." : "Guardar" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
export default {
  data() {
    return {
      show: false,
      loading: false,
      permission: [],
      form: {
        permissions: [],
        name: null,
        guard_name: "api",
      },
    };
  },
  methods: {
    reset() {
      this.form = {
        name: null,
      };
    },
    loadPermission() {
      axios
        .get("permissions")
        .then((res) => {
          this.permission = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    create() {
      if (this.form.name) {
        this.loading = true;
        axios
          .post("roles", this.form)
          .then((res) => {
            if (res.data.message == "success") {
              this.show = false;
              this.loading = false;
              this.$emit("loadRoles", true);
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      } else {
        toastr.info(`Todos los campos son obligatorios`);
      }
    },
  },
};
</script>
